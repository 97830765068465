import React from "react"
import { Link } from "gatsby-plugin-intl"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/blog-layout"
import SEO from "../components/seo"
import BlogAuthor from "../components/blog-author"

// Styles
import "../styles/templates/blog-list.scss"

const BlogPage = ({ data, pageContext }) => {
  const { tag } = pageContext
  const { allContentfulBlogPost } = data
  const posts = allContentfulBlogPost.edges
  return (
    <Layout pageName="blog-list" noHero>
      <SEO title="Twispay - About Payments" />
      <section className="section ">
        <div className="container">
          <h1 className="title has-text-centered">
            Posts tagged by "{tag}"
          </h1>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns is-multiline has-margin-bottom-5">
            {posts.map(post => (
              <div key={post.node.id} className="column is-one-third">
                <div className="is-card">
                  <div className="thumbnail-wrapper">
                    <Link to={`/blog/${post.node.slug}`}>
                      <BackgroundImage
                        Tag="div"
                        className="thumbnail"
                        fluid={post.node.headerImage.fluid}
                      />
                    </Link>
                  </div>
                  <div className="post-content is-flex is-vspaced">
                    <h2 className="title is-5">
                      <Link to={`/blog/${post.node.slug}`}>
                        {post.node.title}
                      </Link>
                    </h2>
                    <div>
                      <BlogAuthor
                        bgImg={post.node.author.image.fixed.src}
                        name={post.node.author.name}
                        className="has-margin-bottom-3"
                      />
                      <p className="has-margin-bottom-5">
                        {post.node.shortDescription}
                      </p>
                      <Link
                        to={`/blog/${post.node.slug}`}
                        className="button is-success is-fullwidth"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogTagListQuery($tag: String!) {
    allContentfulBlogPost(
      sort: { fields: [createdAt], order: DESC }
      filter: {tags: {eq: $tag}}
    ) {
      edges {
        node {
          id
          title
          slug
          createdAt
          shortDescription
          author {
            name
            image {
              fixed(width: 120) {
                src
              }
            }
          }
          headerImage {
            fluid(maxHeight: 250) {
              base64
              aspectRatio
              src
              sizes
              srcSet
            }
          }
        }
      }
    }
  }
`

export default BlogPage
